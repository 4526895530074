<template>
  <div class="container">
      <img src="../assets/images/tips.png" style="width: 100%">
  </div>
<!--  <div class="container">-->
<!--    <div class="bg-top" :style="{backgroundImage:'url('+require('@/assets/images/bg-top2.png')+')'}">-->
<!--      <img class="qrcode" :src="require('@/assets/images/qrcode.png')" alt="">-->
<!--      <img class="explain" @click="$router.push('/explain')" :src="require('@/assets/images/explain2.png')" alt="">-->
<!--    </div>-->
<!--    <div class="box" :style="{backgroundImage:'url('+require('@/assets/images/bg2.png')+')'}">-->
<!--        <div class="time" style="width: 100%; height: auto; margin: 0 auto" >-->
<!--            <p> 投票规则</p>-->
<!--            <p style="font-weight: normal;line-height: 150%; color:#FF0000">每个手机号每天可以投票10次</p>-->
<!--            <p style="font-weight: normal;line-height: 150%;color:#FF0000">每个作品每天只能投票一次</p>-->
<!--            <p> 投票时间：2024年2月10日至2月19日</p>-->
<!--            <p></p>-->
<!--        </div>-->
<!--      <div class="time" :style="{backgroundImage:'url('+require('@/assets/images/time.png')+')'}">-->
<!--        <p>距离投票结束时间还有：<span><van-count-down :time="time" format="DD天HH小时mm分" /></span></p>-->
<!--      </div>-->
<!--      <div class="tabs">-->
<!--        <img @click="switchTabs(1)" class="tab tab-all" :src="tab==1 ? require('@/assets/images/all-on2.png') : require('@/assets/images/all2.png')" alt="">-->
<!--        <img @click="switchTabs(2)" class="tab tab-rank" :src="tab==2 ? require('@/assets/images/rank-on2.png') : require('@/assets/images/rank2.png')" alt="">-->
<!--      </div>-->
<!--      <div class="content">-->
<!--        <div class="search">-->
<!--          <img @click="doSearch" class="search-button" :src="require('@/assets/images/search-button.png')" alt="">-->
<!--          <input type="text" v-model="keyword" class="search-input" placeholder="输入序号搜索作品">-->
<!--        </div>-->
<!--        <div class="lists">-->
<!--          <van-list-->
<!--            v-model="loading"-->
<!--            :finished="finished"-->
<!--            finished-text="没有更多了"-->
<!--            @load="onLoad"-->
<!--          >-->
<!--            <div class="item-box" v-for="(item, index) in list" :key="index">-->
<!--              <div class="item" :style="{backgroundImage:'url('+require('@/assets/images/item-bg.png')+')'}" @click="$router.push('/content/'+item.id)">-->
<!--                <img class="item-images" :src="item.zuopin=='' ? require('@/assets/images/default_picture.png') : item.zuopin" alt="">-->
<!--                <img class="item-number" :src="require('@/assets/images/item-number.png')" alt="">-->
<!--                <span class="item-number-text">{{ item.id }}</span>-->
<!--                <div class="title">{{ item.title }}</div>-->
<!--                <div class="number">票数：<span>{{ item.votenum }}票</span></div>-->
<!--              </div>-->
<!--              <img class="vote" :src="require('@/assets/images/vote.png')" alt="" @click="changeVote(item.id)">-->
<!--            </div>-->
<!--            <div class="clear"></div>-->
<!--          </van-list>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="line"></div>-->
<!--    </div>-->
<!--    &lt;!&ndash; <van-overlay :show="voteing">-->
<!--      <van-loading type="spinner" />-->
<!--    </van-overlay> &ndash;&gt;-->
<!--    <van-overlay :show="captcha" @click="captcha=false">-->
<!--      <div id="embed-captcha"></div>-->
<!--    </van-overlay>-->
<!--  </div>-->
</template>

<script>
import { Toast } from 'vant';
import $ from 'jquery'
import "@/assets/js/gt"
// 引入微信
import wx from "weixin-js-sdk";

export default {
  name: 'Home',
  data () {
    return {
      id: 0,
      tab: 1,
      list: [],
      page: 1,
      page_size: 10,
      loading: false,
      finished: false,
      sort: '',
      voteing: false,
      captcha: false,
      time: '',
      keyword: '',
    }
  },
  mounted() {
    var dateStr = "2024/2/19 23:59:59";
    var date = new Date(dateStr);
    var date1 = new Date();
    this.time = date.getTime() - date1.getTime();

    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf('micromessenger') != -1;
    if(isWeixin){
      this.wechatSign();
    }
  },
  methods: {
    changeVote(id) {
      this.id = id;
      this.captcha = true;
      this.initGT()
    },
    doSearch() {
      this.tab = 1;
      this.sort = '';
      this.list = [];
      this.page = 1;
      this.onLoad();
    },
    doVote(validate) {
      let that = this
      console.log(validate)
      if(that.voteing){
        return false;
      }
      that.voteing = true;
      validate.id = that.id
      that.$api.post('/default/addVote', validate).then(function (response) {
        console.log(response)
        that.voteing = that.captcha = false;
        if (response.data.code === 0) {
          // 增加票数
          that.list.forEach(function(item, index){
            if(that.list[index].id==that.id){
              that.list[index].votenum++
            console.log(that.list[index].votenum)
            }
          })
          Toast.success('投票成功！');
        } else {
          Toast.fail(response.data.msg);
        }
        $("#embed-captcha").html('')
      }).catch(function (error) {
        that.voteing = false;
        Toast.fail(error);
      });
    },
    switchTabs(tab) {
      this.tab = tab;
      this.sort = tab==1 ? '' : 'votenum';
      if(tab==2){
        this.keyword = '';
      }
      this.list = [];
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
      let that = this
      that.$api.get('/default/index', {
        params: {
          page: that.page,
          limit: that.page_size,
          sort: that.sort,
          id: that.keyword,
        }
      }).then(function (response) {
        console.log(response)
        // 加载状态结束
        that.loading = false;
        if(response.data.data.records.length < 10){
          that.finished = true;
        }
        that.page += 1;
        if(that.keyword==''){
          that.list = that.list.concat(response.data.data.records);
        }else{
          that.list = response.data.data.records;
        }
      }).catch(function (error) {
        console.log(error);
        Toast.fail(error);
      });
    },
    initGT(){
      $("#embed-captcha").html('')
      var that = this
      that.$api.get('/default/geetestReg?t='+(new Date()).getTime()).then(res=>{
        let data=res.data.data
        // data.new_captcha = 1
        // eslint-disable-next-line no-undef
        initGeetest({
          gt: data.gt,
          challenge: data.challenge,
          // new_captcha: 1, // 用于宕机时表示是新验证码的宕机
          offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
          product: "bind", // 产品形式，包括：float，popup
          // width: "100%"
        }, function (captchaObj) {
          // 将验证码加到id为captcha的元素里，同时会有三个input的值：geetest_challenge, geetest_validate, geetest_seccode
          captchaObj.appendTo("#embed-captcha");
          captchaObj.onReady(function(){
            console.log(111)
          }).onSuccess(function(){
            var validate = captchaObj.getValidate();
            that.doVote(validate)
          }).onError(function(){
            console.log(333)
          })
        });
      })
    },
    /*微信分享*/
    wechatSign() {
      let url = document.URL;
      if (url) {
        let that = this
        this.$api.get('/wechat/sign', {
          params: {
            url: url
          }
        }).then(function (res) {
          console.log(res)
          // 加载状态结束
          if (res.data.code === 0) {
            that.shareJs(res.data.data)
          } else {
            // alert(JSON.stringify(res));
            Toast.fail(res.data.msg);
          }
        }).catch(function (error) {
          console.log(error);
          Toast.fail(error);
        });
      }
    },
    /*微信分享*/
    shareJs(jssdk, options) {
      // alert(JSON.stringify(jssdk));
      wx.config({
        debug: false,//是否开启调试功能，这里关闭！
        appId: jssdk.appId,//appid
        timestamp: parseInt(jssdk.timestamp), //时间戳
        nonceStr: jssdk.nonceStr, //生成签名的随机字符串
        signature: jssdk.signature,//签名
        jsApiList: [
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          'updateTimelineShareData',
          'updateAppMessageShareData'
        ]
      });
      var defaults = {
        title: "【投票评选】广西第二届“永中软件杯”版权公益海报设计大赛",
        desc: "投票你最喜欢的海报～",
        link: location.href, //分享页面地址,不能为空，这里可以传递参数！！！！！！！
        imgUrl: 'https://www.gxbdhi.com.cn/logo.jpg', //分享是封面图片，不能为空
        success: function () { } //分享成功触发
      }
      // 合并对象，后面的替代前面的！
      options = Object.assign({}, defaults, options);
      console.log(options)
      wx.ready(function () {
        var thatopts = options;
        // 分享到朋友圈
        wx.updateTimelineShareData({
          title: thatopts.title, // 分享标题
          link: thatopts.link, // 分享链接
          imgUrl: thatopts.imgUrl, // 分享图标
          success: function () {
            // alert("成功");
          }
        });
        // 分享给朋友
        wx.updateAppMessageShareData({
          title: thatopts.title, // 分享标题
          desc: thatopts.desc, // 分享描述
          link: thatopts.link, // 分享链接
          imgUrl: thatopts.imgUrl, // 分享图标
          success: function () {
            // alert("成功");
          }
        });
        // 分享到朋友圈
        wx.onMenuShareTimeline({
          title: thatopts.title, // 分享标题
          link: thatopts.link, // 分享链接
          imgUrl: thatopts.imgUrl, // 分享图标
          success: function () {
            // alert("成功");
          }
        });
        // 分享给朋友
        wx.onMenuShareAppMessage({
          title: thatopts.title, // 分享标题
          desc: thatopts.desc, // 分享描述
          link: thatopts.link, // 分享链接
          imgUrl: thatopts.imgUrl, // 分享图标
          success: function () {
            // alert("成功");
          }
        });
      });
    }
  },
}
</script>

<style scoped>
  .header{width: 100%;display: block;}
  .bg-top{height:0;padding-bottom: 149%;background-size: 100%;position: relative;}
  .box{padding: 0 5% .35rem;text-align: center;}
  .qrcode{position:absolute;width:90%;top:5.4rem;left: 5%;}
  .explain{position:absolute;width: 40%;top: 9.75rem;left: 30%;}
  .time{width:80%;height:80px;line-height:80px;margin: 0 10% 0.3rem;font-size:28px;background-size: 100% 100%;text-align: center;}
  .time p{color:#4F4E4D;margin:0;font-weight: 600;}
  .time p .van-count-down{color:#E16002;display:inline-block;}
  .tabs{box-sizing: border-box;}
  .tabs .tab{width:50%;display:block;float:left;}
  .content{border:1px solid #000;border-top:none;clear:both;padding-top:40px;background-color: rgb(195,227,255);}
  .search{margin:0 20px;position: relative;height:80px;border:1px solid #000;border-radius: 10px;}
  .search .search-button{position: absolute;right:30px;top:20px;width:40px;}
  .search-input{width:80%;height:80px;border:none;background: none;font-size:30px;color:#A6B2FF;display:block;padding:0 15px;}
  .lists{padding:20px 10px;margin-bottom:10px;min-height:520px;}
  .lists .item-box{width:50%;float:left;margin-bottom:20px;}
  .lists .item{width:calc(100% - 20px);height:500px;margin:10px;background-size: 100% 100%;position: relative;}
  .lists .item .item-images{width:calc(100% - 5px);height:380px;margin:2px;border-radius: 20px;display:block;}
  .lists .item .item-number{width:50%;height:40px;position: absolute;top:0;left:25%;z-index:10;}
  .lists .item .item-number-text{width:50%;height:40px;line-height:40px;position: absolute;top:0;left:25%;z-index:11;font-size:28px;font-weight: bold;color:#fff;text-align: center;}
  .lists .item .title{font-size:28px;padding:0 10px;color:#333;font-weight: 600;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;overflow: hidden;margin-top:20px;}
  .lists .item .number{font-size:26px;padding:0 10px;color:#737374;margin-top:5px;}
  .lists .item .number span{color:#F48503;font-weight: 600;}
  .lists .vote{width:60%;margin:20px 20%;display:block;}
  .clear{clear:both;}
  .line{height:20px;width:100%;}
  .van-overlay{z-index:99;}
  .van-loading{left:50%;top:50%;}
  #embed-captcha{left:100px;top:40%;position: absolute}
</style>
