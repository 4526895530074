import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Content from '../views/Content.vue'
import Explain from '../views/Explain.vue'
import Test from '../views/Test.vue'


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/content/:id(\\d+)',
    name: 'Content',
    component: Content
  },
  {
    path: '/explain',
    name: 'Explain',
    component: Explain
  }
]

const router = new VueRouter({
  routes,
  // base: process.env.BASE_URL,
  // strict: 'production',
  // mode: 'hash',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    if (savedPosition) {
      // hack 滚动到保存的位置， 原生方法失效的问题
      setTimeout(() => {
        window.scrollTo(savedPosition.x, savedPosition.y)
      }, 100)
    } else {
      const {
        meta: { keepAlive = false, toTop = false },
      } = from
      if (keepAlive && !toTop) {
        from.meta.savedPosition =
          document.body.scrollTop || document.documentElement.scrollTop
      }
      setTimeout(() => {
        window.scrollTo(0, to.meta.savedPosition)
      }, 100)
    }
  },
})

/**
 * 路由守卫 登录检测 islogin
 *
 */
/* router.beforeEach((to, from, next) => {
  const logged = !!window.$lstore.hasData('LHXTD_ACCESS_TOKEN')
  console.log(to)
  if (logged) {
    next()
  } else {
    to.path!='/signin'
      ? next({ path: '/signin', query: { redirect: to.fullPath } })
      : next()
  }
}) */

export default router
