<template>
    <div class="container">
        <img class="banner" :src="require('@/assets/images/banner-top2.png')" alt="">
        <div class="box" :style="{backgroundImage:`url(${require('@/assets/images/bg2.png')})`}">
            <div class="content">
                <div class="number" :style="{backgroundImage:`url(${require('@/assets/images/number-bg.png')})`}">
                    参赛序号：{{ content.id }}
                </div>
                <div class="title">{{ content.title }}</div>
                <div class="vote">当前票数：<span>{{ content.votenum }}票</span></div>
                <van-divider :style="{ color: '#CDCDCD', borderColor: '#CDCDCD'}" />
                <div class="html-box">
                  <img class="zuopin" :src="content.zuopin" alt="">
                  <div class="html" v-if="content.content" v-html="content.content"></div>
                  <div class="empty" v-else>暂无作品介绍</div>
                </div>
            </div>
            <div class="bottom">
                <img @click="$router.go(-1)" class="back" :src="require('@/assets/images/back.png')" alt="">
                <div class="voted-box" :style="{backgroundImage:'url('+require('@/assets/images/vote-number.png')+')'}">
                    <div class="d1" @click="changeVote(content.id)">投一票</div>
                    <div class="d2">现有票数：{{ content.votenum }}</div>
                </div>
            </div>
        </div>
        <van-overlay :show="captcha" @click="captcha=false">
          <div id="embed-captcha"></div>
        </van-overlay>
    </div>
</template>

<script>
import { Toast } from 'vant';
import $ from 'jquery'
import "@/assets/js/gt"

export default {
  name: 'Content',
  data () {
    return {
      content: [],
      voteing: false,
      captcha: false,
    }
  },
  mounted () {
    this.getContent()
  },
  methods: {
    changeVote(id) {
      $("#embed-captcha").html('')
      this.id = id;
      this.captcha = true;
      this.initGT()
    },
    doVote(validate) {
      console.log(validate)
      let that = this
      if(that.voteing){
        return false;
      }
      that.voteing = true;
      validate.id = that.id
      that.$api.post('/default/addVote', validate).then(function (response) {
        console.log(response)
        that.voteing = that.captcha = false;
        if (response.data.code === 0) {
          Toast.success('投票成功！');
        } else {
          Toast.fail(response.data.msg);
        }
      }).catch(function (error) {
        that.voteing = false;
        Toast.fail(error);
      });
    },
    getContent() {
      let that = this
      this.$api.get('/default/info/'+that.$route.params.id).then(function (response) {
        console.log(response)
        if (response.data.code === 0) {
            that.content = response.data.data
        } else {
            Toast.fail(response.data.msg);
        }
      }).catch(function (error) {
        console.log(error);
        Toast.fail(error);
      });
    },
    initGT(){
      var that = this
      that.$api.get('/default/geetestReg?t='+(new Date()).getTime()).then(res=>{
        let data=res.data.data
        // data.new_captcha = 1
        // eslint-disable-next-line no-undef
        initGeetest({
          gt: data.gt,
          challenge: data.challenge,
          // new_captcha: 1, // 用于宕机时表示是新验证码的宕机
          offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
          product: "bind", // 产品形式，包括：float，popup
          width: "100%"
        }, function (captchaObj) {
          // 将验证码加到id为captcha的元素里，同时会有三个input的值：geetest_challenge, geetest_validate, geetest_seccode
          captchaObj.appendTo("#embed-captcha");
          captchaObj.onReady(function(){
            console.log(111)
          }).onSuccess(function(){
            var validate = captchaObj.getValidate();
            that.doVote(validate)
          }).onError(function(){
            console.log(333)
          })
        });
      })
    },
  },
}
</script>

<style scoped>
  .banner{width:100%;display: block;}
  .box{padding-bottom:150px;position: relative;}
  .content{background: #fff;border-radius: 20px;margin:0 30px 20px 30px;position: relative;padding:80px 0 40px 0;}
  .number{width:335px;height:80px;line-height:80px;background-size: 100% 100%;background-repeat: no-repeat;font-size:30px;font-weight: bold;text-align: center;position: absolute;left:calc(50% - 167px);top:-40px;color:#fff;text-shadow: #333 3px 0 0, #333 0 3px 0, #333 -3px 0 0, #333 0 -3px 0;}
  .title{font-size:40px;padding:0 30px;color:#333;font-weight: 600;}
  .vote{font-size:26px;padding:0 30px;color:#737374;margin-top:20px;}
  .vote span{color:#F48503;font-weight: 600;}
  .html{font-size:28px;color:#494949;}
  .bottom{width:350px;position: absolute;bottom:40px;left:calc(50% - 175px);}
  .voted-box{width:260px;height:80px;font-size:20px;color:#fff;text-align: center;position: relative;background-size: 100% 100%;float:right;}
  .voted-box .d1{font-size:30px;font-weight: bold;}
  .back{width:70px;float:left;}
  .empty{font-size:24px;color:#666;}
  #embed-captcha{left:100px;top:40%;position: absolute}
  .html-box{padding:0 30px;}
  .zuopin{width:100%;}
</style>