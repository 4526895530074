<template>
    <div class="container">
        <img class="explain" :src="require('@/assets/images/explain-info.png')" alt="">
        <div class="bottom">
            <img @click="$router.go(-1)" class="back" :src="require('@/assets/images/back.png')" alt="">
            <img @click="$router.push('/')" class="vote" :src="require('@/assets/images/home.png')" alt="">
        </div>
    </div>
</template>

<script>
export default {
  name: 'Explain',
}
</script>

<style scoped>
    .container{position: relative;}
    .explain{width:100%; height:100%;display:block;}
    .bottom{width:350px;position: absolute;bottom:20px;left:calc(50% - 175px);}
    .back{width:75px;height:80px;float:left;}
    .vote{width:260px;height:80px;float:right;}
</style>